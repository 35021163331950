import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import axios from "axios"
import ImageRenderer from "../components/atoms/ImageRenderer"
import useGenerateCsrfTokenForAPI from "../hooks/useGenerateCsrfTokenForAPI"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarning } from "@fortawesome/free-solid-svg-icons"

const AuthPage = () => {
  const csrfToken = useGenerateCsrfTokenForAPI()
  const [verificationStatus, setVerificationStatus] = useState(null)

  useEffect(() => {
    if (!csrfToken) {
      return
    }

    const fetchData = async () => {
      try {
        // Extract query parameters from URL
        const queryParams = new URLSearchParams(window.location.search)
        const payload = Object.fromEntries(queryParams.entries())

        // Create Axios instance
        const instance = axios.create({
          baseURL: process.env.GATSBY_SYNKLI_API_ENDPOINT,
          withCredentials: true,
        })

        const response = await instance.post(
          "/site/handle-email-click",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              app_environment: "business_website",
              "x-csrf-token": csrfToken,
            },
          }
        )

        if (response.status === 200) {
          if (response?.data?.data?.redirect_url) {
            // Redirect to the URL provided in the response
            window.location.href = response.data.data.redirect_url
            return
          } else {
            const userAgent =
              navigator.userAgent || navigator.vendor || window.opera

            // Redirect based on device type
            if (/iphone|ipad|ipod/i.test(userAgent)) {
              window.location.href =
                "https://apps.apple.com/au/app/synkli/id6479931845"
            } else if (/android/i.test(userAgent)) {
              window.location.href =
                "https://play.google.com/store/apps/details?id=com.synkli.au&hl=en&pli=1"
            } else {
              setVerificationStatus("error")
            }
            return
          }
        } else {
          setVerificationStatus("error")
        }
      } catch (error) {
        setVerificationStatus("error")
      }
    }

    fetchData()
  }, [csrfToken]) // Empty dependency array ensures this runs only on page load

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Seo title="Synkli" />
      <div className="bg-[#FAF9F6] min-h-[100vh] px-[20px]">
        <div className="synkli-section--horizontal-space flex flex-wrap items-center justify-between pt-6 sm:pt-10 pb-4 !px-0">
          <div className="w-[110px]">
            <Link to="/">
              <ImageRenderer
                img={`synkli-logo-transparent`}
                alt={"synkli-logo-transparent"}
              />
            </Link>
          </div>
        </div>
        <div className="bg-white min-h-[70vh] rounded-[18px] text-center border-b-[20px] border-[#101828] max-w-[950px] mx-auto mt-5 py-14 px-5">
          {verificationStatus === "success" ? (
            <>
              <div className="max-w-[110px] mx-auto">
                <ImageRenderer
                  img={`synkli-user-email-verification-success`}
                  alt={`synkli-user-email-verification-success`}
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Email Verified Successfully!
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">Thank you!</span> Your email
                has been successfully verified. You can now return to your
                application Synkli.
              </p>
            </>
          ) : verificationStatus === "error" ? (
            <>
              <div className="max-w-[110px] mx-auto">
                <FontAwesomeIcon
                  icon={faWarning}
                  className="text-[#dd0000] text-[80px]"
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Something Went Wrong!
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">We're sorry!</span> The link
                you used is either invalid or has expired. Please try again
                later or contact our support team for assistance.
              </p>
            </>
          ) : (
            <>
              <div className="max-w-[140px] mx-auto mb-5">
                <ImageRenderer img={`synkli-loader`} alt={`synkli-loader`} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AuthPage
