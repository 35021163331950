import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/atoms/Button"
import ImageRenderer from "../components/atoms/ImageRenderer"

const NotFoundPage = () => {
  const [isAuthPage, setIsAuthPage] = useState(true)

  useEffect(() => {
    // Check if the URL contains "/auth/"
    if (window.location.pathname.includes("/auth/")) {
      setIsAuthPage(true) // Show the loader image
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      console.log("in auth if" , window.location.pathname);
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        // Redirect for iOS users
        window.location.href = "https://apps.apple.com/au/app/synkli/id6479931845";
      } else if (/Android/i.test(userAgent)) {
        // Redirect for Android users
        window.location.href = "https://play.google.com/store/apps/details?id=com.synkli.au&hl=en&pli=1"
      }else{
        window.location.href = "/";
      }
    } else {
      setIsAuthPage(false)
    }
  }, [])

  return (
    <>
      <Seo title="404: Not found" />
      {isAuthPage ? (
        // Loader image when "/auth/" is in the URL
        <div className="bg-[#FAF9F6] min-h-[100vh] px-[20px] pb-[50px]">
          <div className="synkli-section--horizontal-space pt-6 sm:pt-10 pb-4 !px-0">
            <div className="max-w-[110px] mx-auto">
              <ImageRenderer img={`synkli-loader`} alt={`synkli-loader`} />
            </div>
          </div>
        </div>
      ) : (
        
        // 404 Page when "/auth/" is not in the URL
        <Layout>
          <div className="synkli-section--horizontal-space pt-10 md:pt-20 pb-28">
            <h1 className="capitalize text-center font-bold text-8xl lg:text-9xl !leading-[1.4em] text-[#B695F8]">
              404
            </h1>
            <p className="mx-auto mb-6 text-center text-lg md:text-4xl font-medium !leading-[1.5em] text-[#0A1E46]">
              Page not found!
            </p>
            <Button
              to={`/`}
              href={`/`}
              text={"Homepage"}
              ec={`primary-btn max-w-[180px] mx-auto`}
            />
          </div>
        </Layout>
      )}
    </>
  )
}

export default NotFoundPage
