import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ImageRenderer from "../components/atoms/ImageRenderer"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import ClientDetailsAccordianBox from "../components/molecules/ClientDetailsAccordianBox"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useGenerateCsrfTokenForAPI from "../hooks/useGenerateCsrfTokenForAPI"
import axios from "axios"

const EthicalClearanceRequest = () => {
  const csrfToken = useGenerateCsrfTokenForAPI()

  const [APIResponse, setAPIResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")

  // useEffect hook that triggers token validation and fetches details
  useEffect(() => {
    const validateToken = async () => {
      if (!token) return setIsLoading("expired")

      if (!csrfToken) {
        return false
      }

      try {
        const instance = axios.create({
          baseURL: process.env.GATSBY_SYNKLI_API_ENDPOINT,
        })

        const response = await instance({
          method: "POST",
          url: "/ethical-clearance/validate-token",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "app_environment": "business_website",
            "x-csrf-token": csrfToken,
          },
          data: {
            token: token,
          },
        })

        if (response.status === 200) {
          fetchDetails() // Call fetchDetails on success
        } else {
          setIsLoading("expired")
        }
      } catch (error) {
        console.error("Error validating token:", error)
        setIsLoading("expired")
      }
    }

    validateToken()
  }, [token, csrfToken]) // Run only when token changes

  // Function to fetch details using the token
  const fetchDetails = async () => {
    try {
      const instance = axios.create({
        baseURL: process.env.GATSBY_SYNKLI_API_ENDPOINT,
      });
      
      const response = await instance({
        method: "GET",
        url: "/ethical-clearance/get-details",
        withCredentials: true,  // Ensure cookies are included
        headers: {
          "Content-Type": "application/json",
          "app_environment": "business_website",
          "x-csrf-token": csrfToken, // Matching the header format from fetch
        },
      });
      

      
      if (response.status === 200) {
        setAPIResponse(response.data.data)
        setIsLoading(false)
      } else {
        setIsLoading("expired")
        console.error("Failed to fetch details:", response.data)
      }
    } catch (error) {
      console.error("Error fetching details:", error)
      setIsLoading("expired")
    }
  }

  const updateStatus = status => {
    setAPIResponse({ ...APIResponse, status: status })
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Seo
        title="Ethical Clearance Request"
        description={`Request accountant for ethical clearance documents`}
      />
      <ToastContainer />

      <div className="bg-[#FAF9F6] min-h-[100vh] px-[20px] pb-[50px]">
        <div className="synkli-section--horizontal-space pt-6 sm:pt-10 pb-4 !px-0">
          <div className="w-[110px]">
            <Link to="/">
              <ImageRenderer
                img={`synkli-logo-transparent`}
                alt={"synkli-logo-transparent"}
              />
            </Link>
          </div>
          {isLoading === false ? (
            <>
              <div className="flex flex-col md:flex-row gap-[20px] mt-12">
                <div className="flex-[3]">
                  <div className="bg-white rounded-[16px] shadow p-6 md:pb-12">
                    <h2 className="text-[22px] leading-[1.2em] font-[600] text-black">
                      Requested By
                    </h2>
                    <div className="text-center py-4 md:py-8">
                      <img
                        src={APIResponse.businessInfo.logo}
                        className="max-w-[200px] w-full mx-auto"
                        alt="Company Logo"
                      />
                      <div className="max-w-[100px] mx-auto mt-7 bg-[#ebebeb] p-[1px]"></div>
                    </div>
                    <h4 className="text-[15px] font-[600] leading-[1.2em] text-black">
                      Accountant Detail
                    </h4>
                    <table className="w-full mt-3">
                      <tbody>
                        <tr>
                          <td className="text-[#666666] text-[13px] leading-[1.2em] w-[50%] pt-4 align-top">
                            Accounting Firm:
                          </td>
                          <td className="text-black text-[13px] leading-[1.2em] w-[50%] pt-4 align-top">
                            {APIResponse.businessInfo.businessName}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-[#666666] text-[13px] leading-[1.2em] w-[50%] pt-4 align-top">
                            Accountant Name:
                          </td>
                          <td className="text-black text-[13px] leading-[1.2em] w-[50%] pt-4 align-top">
                            {APIResponse.businessInfo.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-[#666666] text-[13px] leading-[1.2em] pt-4 align-top">
                            Accountant Email:
                          </td>
                          <td className="text-black text-[13px] leading-[1.2em] w-[50%] pt-4 align-top break-all">
                            {APIResponse.businessInfo.email}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-[#666666] text-[13px] leading-[1.2em] pt-4 align-top">
                            Accountant Phone No:
                          </td>
                          <td className="text-black text-[13px] leading-[1.2em] w-[50%] pt-4 align-top">
                            {APIResponse.businessInfo.phone_number}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-[#666666] text-[13px] leading-[1.2em] pt-4 align-top">
                            Status:
                          </td>
                          <td
                            className={`${
                              APIResponse.status === "pending"
                                ? "text-[#F08201]"
                                : "text-[#008C51]"
                            }  text-[13px] leading-[1.2em] w-[50%] pt-4 align-top capitalize`}
                          >
                            {APIResponse.status}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex-[8] flex flex-col gap-[20px]">
                  {APIResponse.ethicalClearanceDoc.map((item, index) => (
                    <ClientDetailsAccordianBox
                      key={"item" + index}
                      data={item}
                      accountant_name={APIResponse.businessInfo.name}
                      ethical_clearance_id={APIResponse.ethical_clearance_id}
                      message={APIResponse.note}
                      updateStatus={updateStatus}
                      csrfToken={csrfToken}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : isLoading === true ? (
            <div className="max-w-[110px] mx-auto">
              <ImageRenderer img={`synkli-loader`} alt={`synkli-loader`} />
            </div>
          ) : (
            <div className="bg-white min-h-[70vh] rounded-[18px] text-center border-b-[20px] border-[#101828] max-w-[950px] mx-auto mt-5 py-14 px-5">
              <div className="max-w-[110px] mx-auto">
                <ImageRenderer
                  img={`something-wend-wrong-icon`}
                  alt={`something-went-wrong-icon`}
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Something Went Wrong
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">Oops!</span> The link can’t be
                accessed – it may have expired, or something went wrong. Please
                reach out to the accountant for a new link to access the portal.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default EthicalClearanceRequest