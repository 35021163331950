import React, { useState } from "react"
import axios from "axios"
import { toastHandler } from "../../utils/utils"
import useGenerateCsrfTokenForAPI from "../../hooks/useGenerateCsrfTokenForAPI"

const MessageSubmitBox = ({ data, ethical_clearance_id, updateStatus }) => {
  
  const csrfToken = useGenerateCsrfTokenForAPI()
  const [showMessage, setShowMessage] = useState(false)
  const [isReason, setIsReason] = useState("no")
  const handleOptionChange = e => {
    setShowMessage(e.target.value === "yes")
    setIsReason(e.target.value)
  }

  const [reason, setReason] = useState("")
  const [isSubmitting, setIsSubmitting] = useState("")

  const handleReasonChange = event => {
    const sanitizedReason = event.target.value.replace(/[^a-zA-Z0-9\s]/g, "")
    setReason(sanitizedReason)
  }

  const handleReasonSubmit = async event => {
    event.preventDefault() // Prevent default form submission
    setIsSubmitting(true)

    try {
      const instance = axios.create({
        baseURL: process.env.GATSBY_SYNKLI_API_ENDPOINT,
      })

      const response = await instance.post(
        "/ethical-clearance/reason", // Endpoint relative to the baseURL
        {
          ethical_clearance_id: ethical_clearance_id,
          business_client_id: data.business_client_id,
          is_reason: isReason,
          reason: reason,
        },
        {
          headers: {
            "Content-Type": "application/json",
            app_environment: "business_website",
            "x-csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      )

      updateStatus(response?.data?.data?.status)
      toastHandler("Your response submitted successfully!", "success")
    } catch (error) {
      if (error.response && error.response.data) {
        error.response.data.errors.forEach(error => {
          toastHandler(error, "error")
        })
      } else {
        toastHandler("An unexpected error occurred")
      }
    }
    setReason("")
    setIsReason("no")
    setShowMessage(false)
    setIsSubmitting(false)
  }
  return (
    <>
      <form onSubmit={handleReasonSubmit}>
        <div className="bg-[#f8f5fa] rounded-[10px] mt-8 p-6 text-[14px]">
          <div className="flex flex-wrap items-center gap-[20px] justify-between">
            <div>
              Do you have any reason that we should not engage the client?
              <div className="mt-2 flex gap-4">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="engageClient"
                    value="yes"
                    onChange={handleOptionChange}
                    className="hidden"
                  />
                  <span className="rounded-full border-2 border-black p-[2px] w-4 h-4 mr-2 flex items-center justify-center ">
                    <span
                      className={`min-w-2 min-h-2 rounded-full ${
                        showMessage ? "bg-black" : ""
                      }`}
                    ></span>
                  </span>
                  Yes
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="engageClient"
                    value="no"
                    onChange={handleOptionChange}
                    className="hidden"
                  />
                  <span className="rounded-full border-2 border-black p-[2px] w-4 h-4 mr-2 flex items-center justify-center ">
                    <span
                      className={`min-w-2 min-h-2 rounded-full ${
                        !showMessage ? "bg-black" : ""
                      }`}
                    ></span>
                  </span>
                  No
                </label>
              </div>
            </div>
            {!showMessage && (
              <div>
                <button className="group primary-btn flex items-center justify-center gap-[10px] font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-6 rounded-[10px] border-2 text-center py-1 transition duration-300">
                  Submit
                  {isSubmitting && (
                    <span
                      className={`border-4 block border-t-4 border-t-transparent border-white group-hover:border-[#0A1E46] group-hover:border-t-transparent rounded-full w-5 h-5 animate-spin`}
                    ></span>
                  )}
                </button>
              </div>
            )}
          </div>

          {/* Message Section - Conditionally Rendered */}
          {showMessage && (
            <div className="mt-6 text-[14px]">
              <textarea
                className="w-full min-h-[120px] rounded-[16px] border border-[#c9c9c9] p-4"
                placeholder="Enter message here..."
                required
                value={reason}
                onChange={handleReasonChange}
              ></textarea>

              <div className="flex justify-end mt-3">
                <button className="group primary-btn flex items-center justify-center gap-[10px] font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-6 rounded-[10px] border-2 text-center py-1 transition duration-300">
                  Submit
                  {isSubmitting && (
                    <span
                      className={`border-4 block border-t-4 border-t-transparent border-white group-hover:border-[#0A1E46] group-hover:border-t-transparent rounded-full w-5 h-5 animate-spin`}
                    ></span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export default MessageSubmitBox
